import { default as usersZrOnc5TXxSMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue?macro=true";
import { default as applicationZCgeAkGY6CMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue?macro=true";
import { default as attachmentsPIY6gytBk9Meta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue?macro=true";
import { default as contactcLuuDMjNHRMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue?macro=true";
import { default as prescreenYZrVTiaJEUMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue?macro=true";
import { default as scholarship517GkpL6u6Meta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue?macro=true";
import { default as summaryjcEdtybnohMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue?macro=true";
import { default as caseljdRYW6GhlMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case.vue?macro=true";
import { default as case_45assignmentsoQLL2lMItBMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue?macro=true";
import { default as index72yz0Y0TOiMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/index.vue?macro=true";
import { default as new_45caseve7jxoYDcNMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/new-case.vue?macro=true";
import { default as providerlistnrPcVS3jdLMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue?macro=true";
import { default as eligibilityjMugMObsXaMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue?macro=true";
import { default as _91id_93EGhCxrSw2fMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue?macro=true";
import { default as search1k3JtqT1l1Meta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/search.vue?macro=true";
import { default as indexVj2u7fuTexMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue?macro=true";
import { default as notificationsk7uA6ygdvvMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue?macro=true";
import { default as settingsP9x1lyRLOjMeta } from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings.vue?macro=true";
export default [
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersZrOnc5TXxSMeta || {},
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "case",
    path: "/case",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case.vue").then(m => m.default || m),
    children: [
  {
    name: "case-id-application",
    path: ":id()/application",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/application.vue").then(m => m.default || m)
  },
  {
    name: "case-id-attachments",
    path: ":id()/attachments",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/attachments.vue").then(m => m.default || m)
  },
  {
    name: "case-id-contact",
    path: ":id()/contact",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: "case-id-prescreen",
    path: ":id()/prescreen",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/prescreen.vue").then(m => m.default || m)
  },
  {
    name: "case-id-scholarship",
    path: ":id()/scholarship",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/scholarship.vue").then(m => m.default || m)
  },
  {
    name: "case-id-summary",
    path: ":id()/summary",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/case/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "csp-admin-case-assignments",
    path: "/csp-admin/case-assignments",
    meta: case_45assignmentsoQLL2lMItBMeta || {},
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/csp-admin/case-assignments.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-case",
    path: "/new-case",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/new-case.vue").then(m => m.default || m)
  },
  {
    name: "providerlist",
    path: "/providerlist",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/providerlist.vue").then(m => m.default || m)
  },
  {
    name: "public-csp-eligibility",
    path: "/apply/childcare/eligibility",
    meta: eligibilityjMugMObsXaMeta || {},
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/public/csp/eligibility.vue").then(m => m.default || m)
  },
  {
    name: "report-id",
    path: "/report/:id()",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/report/[id].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/search.vue").then(m => m.default || m)
  },
  {
    name: settingsP9x1lyRLOjMeta?.name,
    path: "/settings",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  }
]