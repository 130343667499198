import revive_payload_client_Frd8gASYWa from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_0i8wsLYtBM from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_huatSqtcYP from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_WdtrT1afPN from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_Q0joJrSgLY from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_0zhm6TqmLh from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_2MWhypiRxk from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import scrollbars_client_LljD9FJPTX from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_VzDxZhX5m8 from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_OEPWY3K8fx from "/codebuild/output/src2836392188/src/WDI.Portal/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import amplify_cCHdTinyKG from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/plugins/amplify.ts";
import api_GFfDXud5Cr from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/plugins/api.ts";
import cleave_HD9soKWDBf from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/plugins/cleave.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/plugins/sentry.ts";
import yup_OM8D6DMeq7 from "/codebuild/output/src2836392188/src/WDI.Portal/wdi.portal.client/plugins/yup.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  switch_locale_path_ssr_0i8wsLYtBM,
  i18n_huatSqtcYP,
  slideovers_WdtrT1afPN,
  modals_Q0joJrSgLY,
  colors_0zhm6TqmLh,
  plugin_client_2MWhypiRxk,
  scrollbars_client_LljD9FJPTX,
  presets_VzDxZhX5m8,
  variables_OEPWY3K8fx,
  amplify_cCHdTinyKG,
  api_GFfDXud5Cr,
  cleave_HD9soKWDBf,
  sentry_3AyO8nEfhE,
  yup_OM8D6DMeq7
]